/******************************************************************************
 * Content
 ******************************************************************************/

body {
  padding-bottom: 70px;
  color: var(--global-text-color);
  background-color: var(--global-bg-color);

  h1, h2, h3, h4, h5, h6 {
    scroll-margin-top: 66px;
  }
}

body.fixed-top-nav {
  // Add some padding for the nav-bar.
  padding-top: 56px;
}

body.sticky-bottom-footer {
  // Remove padding below footer.
  padding-bottom: 0;
}

.container {
  max-width: $max-content-width;
}

// Profile
.profile {
  img {
    width: 80%;
  }
}

// TODO: redefine content layout.


/******************************************************************************
 * Publications
 ******************************************************************************/

// TODO: redefine publications layout.


/*****************************************************************************
* Projects
*****************************************************************************/

// TODO: redefine projects layout.
